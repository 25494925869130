<template>
    <div class="serviceObj">
        <van-row>
            <van-col span="22" class="serviceObjContent">
                <div class="serviceObjContent-item">
                    <div class="serviceObjContentIcon">
                         <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/rzzj.png" size="35"/>
                    </div>
                    <div class="serviceObjContentTitle">财富管理机构</div>
                    <div class="serviceObjContentText">独立家族办公室</div>
                    <div class="serviceObjContentText">独立基金销售公司</div>
                    <div class="serviceObjContentText">三方财富机构</div>
                    <div class="serviceObjContentText">券商财富中心</div>
                    <div class="serviceObjContentText">保险代理销售公司</div>
                </div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="22" class="serviceObjContent">
                <div  class="serviceObjContent-item">
                    <div class="serviceObjContentIcon">
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/jg.png" size="35"/>
                    </div>
                    <div class="serviceObjContentTitle">资产管理机构</div>
                    <div class="serviceObjContentText">私募/公募基金管理人</div>
                    <div class="serviceObjContentText">基金子公司</div>
                    <div class="serviceObjContentText">银行理财子公司</div>
                    <div class="serviceObjContentText">企业融资方</div>
                    <div class="serviceObjContentText">保险资管公司</div>
                    <div class="serviceObjContentText">独立资管团队/FA</div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>
<script>
export default {
    name:'serviceObj',
    data(){
        return{

        }
    }
}
</script>