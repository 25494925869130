<template>
    <div class="home">
        <!-- 头部 -->
        <van-row>
            <van-col span="24">
              <headComponent></headComponent>
            </van-col>
        </van-row>
        <!-- banner轮播 -->
        <van-row>
            <van-col span="24">
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" width="100%">
                    <van-swipe-item v-for="(item,index) in banner" :key="index">
                        <van-image fit="cover" width="100%" height="auto" lazy-load :src="item.url"/>
                    </van-swipe-item>
                </van-swipe>
            </van-col>
        </van-row>
        <!-- 公司描述 -->
        <van-row>
            <van-col span="24" class="companyIntroduction">
                <van-row>
                    <van-col  span="24" class="companyIntroductionTitle">瑞智科技</van-col>
                </van-row>
                <van-row>
                    <van-col  span="24"  class="companyIntroductionContent" style="text-indent:22px">成都瑞智数联科技有限公司（简称：“瑞智科技”）成立于2020年，是一家通过利用移动互联网、大数据、AI人工智能、云服务等先进技术，集自主研发、信息技术咨询与推广、运营管理于一体的创新型科技企业。<br></van-col>
                    <van-vol span="24" class="companyIntroductionContent" style="text-indent:22px"><br>
瑞智科技一直专注于为金融产业提供科技服务，目前已推出--财瑞通科技平台，致力于为资产管理机构、财富管理机构、投资顾问、 投资人提供综合性资产与财富管理SaaS技术服务。<br>财瑞通平台通过对金融机构及从业者进行科技化赋能，通过从业务开放、能力开放、技术开放三方面携手合作伙伴向我们的客户提供最优质的服务和选择，并提供营销推广、产品运营、投后管理、资产评价、营销获客、资产配置、基金评价、团队管理、在线培训等科技服务。</van-vol>
                    <van-vol span="24" class="companyIntroductionContent" style="text-indent:22px">瑞智科技建立了专业的资产定价分析系统、基金评价体系、投资者行为分析、投资组合管理等基于机器学习算法的投资辅助系统，辅助金融机构和投资者识别投资机会，管理投资风险。
                    </van-vol><br>
                    <van-vol span="24" class="companyIntroductionContent" style="text-indent:22px">目前瑞智科技已是华为的联运合作伙伴、华为云云商店服务商，财瑞通已通过华为全栈国产化鲲鹏架构兼容认证并与华为合作联运。
                    </van-vol>
                </van-row>
            </van-col>
        </van-row>
        <!-- 数量 -->
        <van-row class="userStatistics">
            <van-col span="11" class="userStatisticsItem">
                <div class="statisticsItemSize"><span class="statisticsItemSizeText">400</span><span>+</span></div>
                <div class="statisticsItemIcon"></div>
                <div class="statisticsItemLabel">已注册投顾数量</div>
            </van-col>
            <van-col span="11" class="userStatisticsItem">
                <div class="statisticsItemSize"><span class="statisticsItemSizeText">2000</span><span>+</span></div>
                <div class="statisticsItemIcon"></div>
                <div class="statisticsItemLabel">累计客户服务数量</div>
            </van-col>
            <van-col span="11" class="userStatisticsItem">
                <div class="statisticsItemSize"><span class="statisticsItemSizeText">100</span><span>亿</span></div>
                <div class="statisticsItemIcon"></div>
                <div class="statisticsItemLabel">累计完成交易规模</div>
            </van-col>
            <!-- <van-col span="11" class="userStatisticsItem">
                <div class="statisticsItemSize"><span class="statisticsItemSizeText">500</span><span>+</span></div>
                <div class="statisticsItemIcon"></div>
                <div class="statisticsItemLabel">合作伙伴</div>
            </van-col> -->
        </van-row>
        <!-- Our role -->
        <van-row>
            <van-col span="24" class="roleContainer">
                <div class="roleA">
                    <div class="roleAContent">
                         <div class="roleAContentDescption">借助不断升级迭代的数字科技平台服务，成为客户提质、降本、增效、可持续发展壮大的引擎。自主研发的核心系统采用微服务架构，业务数据化，运营数字化的全方位解决方案；具备隐私保护、安全可信、高可靠、高弹性、简单易用等特点。</div>
                         <div class="roleAContentTitle">降本增效 安全可靠 </div>
                    </div>
                </div>
                <div class="roleB">
                    <div class="roleBContent">
                        <div class="roleBContentTitle">高效链接 服务产业</div>
                        <div class="roleBContentDescption">连通全产业链,高效链接财富端与资产端。通过对金融机构及从业者的科技化赋能，让资本与产业深入对接、高效流转，从而服务实体经济，推动产业创新。</div>
                    </div>
                </div>
                <div class="roleCircle">
                    <div>
                        <div class="roleCircleTop">以科技</div>
                        <div class="roleCircleBottom">赋能产业</div>
                    </div>
                </div>
            </van-col>
        </van-row>
        <!-- 脚部 -->
        <van-row>
            <van-col span="24">
                <footComponent></footComponent>
            </van-col>
        </van-row>
    </div>
</template>
<script>
import headComponent from '@/views/common/header.vue'
import footComponent from '@/views/common/footer.vue'
export default {
    name:'home',
    data(){
        return{
            banner:[
                {
                    id:'1',
                    url:'https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner1.png'
                },
                {
                    id:'2',
                    url:'https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner2.png'
                },
                {
                    id:'3',
                    url:'https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner3.jpg'
                }
            ]
        }
    },
    components:{
        headComponent,
        footComponent
    },
    methods:{
       
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';
</style>
