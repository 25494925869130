<template>
    <div class="productService">
          <!-- 头部 -->
        <van-row>
            <van-col span="24">
              <headComponent></headComponent>
            </van-col>
        </van-row>
        <van-row>
            <van-image width="100%" height="auto"  fit="contain" :src="banner"/>
        </van-row>
        <!-- 切换的内容 -->
        <van-tabs v-model="activeName" @click="onClick" background="#fff" color="#4D96F4" title-active-color="#4D96F4">
            <van-tab title="服务对象" name="first">
                <serviceObj></serviceObj>
            </van-tab>
            <van-tab title="服务内容" name="second">
                <serviceContent></serviceContent>
            </van-tab>
        </van-tabs>
        <van-row></van-row>
         <!-- 脚部 -->
        <van-row>
            <van-col span="24">
                <footComponent></footComponent>
            </van-col>
        </van-row>
    </div>
</template>
<script>
import headComponent from '@/views/common/header.vue';
import footComponent from '@/views/common/footer.vue';
import serviceObj from '@/views/productService/common/serviceObj.vue';
import serviceContent from '@/views/productService/common/serviceContent.vue';
export default {
    name:'productService',
    data(){
        return{
            activeName:'first',
            banner:"https://web.oss-cn-chengdu.aliyuncs.com/wap-website/serveBanner.png"
        }
    },
    components:{
        headComponent,
        footComponent,
        serviceObj,
        serviceContent
    },
    methods:{
         onClick(name, title) {
             if(name == 'second'){
                 this.banner = "https://web.oss-cn-chengdu.aliyuncs.com/wap-website/serveContent.png"
             }else {
                 this.banner = "https://web.oss-cn-chengdu.aliyuncs.com/wap-website/serveBanner.png"
             }
           console.log( title)
         },
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';
</style>