<template>
    <div class="foot">
        <van-row>
            <van-col span="24">
                <div class="copyright">版权所有 @ 2021 成都瑞智数联科技有限公司</div>
                <div class="record"><a href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2021026273号</a> </div>
                <div class="footIcon">
                    <div class="footIconItem" @click="clickFootNav('wechat')">
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/footWechat.png" size="2.2rem"/>
                    </div>
                    <div class="footIconItem" @click="clickFootNav('smallProgram')">
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/footSmallProgram.png" size="2.2rem"/>
                    </div>
                    <div class="footIconItem" @click="clickFootNav('phone')">
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/footPhone.png"  size="2.2rem"/>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>
<script>
import bus from '@/utils/bus.js' 
export default {
    name:'foot',
    data(){
        return{}
    },
    methods:{
        clickFootNav(item){
            bus.$emit('message',item)
        }
    }
   
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';
</style>
