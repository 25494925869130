<template>
    <div class="home">
        <!-- 头部 -->
        <van-row>
            <van-col span="24">
                <headComponent></headComponent>
            </van-col>
        </van-row>
        <div class="bgImage"></div>
        <div class="introduceWrap">
            <div class="title">
                商务合作
            </div>
            <div class="content">
                数字开启未来，服务促进发展，瑞智科技坚持务实创新、开放合作、互利共赢。联合以财富管理为终身事业、有共同价值观的团队，共同打造平等互助、独立决策、优势资源共享的高端财富管理联盟服务平台。
            </div>
            <div class="footer">
                <div>
                    <img src="../../assets/image/dian.png" alt=""/>
                    <span>028-85028556</span>
                </div>
                <div> 
                    <img src="../../assets/image/youjian.png" alt=""/>
                    <span>marketing@taotra.com</span>
                </div>
            </div>
        </div>
        <!-- 脚部 -->
        <van-row>
            <van-col span="24">
                <footComponent></footComponent>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import headComponent from '@/views/common/header.vue'
import footComponent from '@/views/common/footer.vue'
export default {
    name:'home',
    components:{
        headComponent,
        footComponent
    },
    data(){
        return{
            
        }
    },
    methods:{

    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.home {
    min-height:100%;
    box-sizing: border-box;
    overflow-x: hidden;
    // padding-bottom: 3.4rem;
}

.bgImage{
    background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-2.png') no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 18.1rem;
}

.introduceWrap {
    // max-width: 21.6rem;
    min-height: 18rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin:0 auto;
    margin-top:-2.8rem;
    margin-bottom:3.5rem;
    padding:1.7rem 1.8rem 3.3rem 1rem;
    box-sizing: border-box;
    margin-left: 1rem;
    margin-right: 1rem;

    .title {
        height: 1.1rem;
        font-family: PingFang SC;
        font-size: 1.1rem;
        font-weight: 800;
        color: #333333;
        line-height: 1.1rem;
        margin-bottom: 1.3rem;
    }

    .content {
        width: 100%;
        min-height: 6.1rem;
        font-size: 0.9rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 1.3rem;
        margin-bottom: 2.2rem;
    }

    .footer{
        display: flex;
        flex-direction: column;

        div{
            display: flex;
            align-items: center;
            margin-bottom:1.2rem;
            width: 11.7rem;
            height: 1rem;
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #000000;
            line-height: 1rem;
            span {
                margin-left: 0.7rem;
            }
        }

        img{
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

</style>