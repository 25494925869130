<template>
    <div>
        <van-nav-bar  left-arrow>
            <template #left>
                <van-image width="80" height="auto" src="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/logo.png"/>
            </template>
            <template #right>
                    <div class="header-tight" @click="showPop">
                        <div>
                            <van-icon :name="show ? 'cross' : 'wap-nav'"  color="#000" size="1.4rem"/>
                        </div>
                        <div>
                            <span class="header-menu">菜单</span>
                        </div>
                    </div>
            </template>
        </van-nav-bar>
        <van-popup v-model="show" position="top" overlay-class="popLog" :style="{ height: '100%',marginTop:'45px' }">
            <van-cell-group inset>
                <van-cell title="网站首页" is-link @click="goRouter('/')" />
                <van-cell title="产品服务" is-link @click="goRouter('/productService')"/>
                <van-cell title="资讯动态" is-link @click="goRouter('/dynamicInformation')"/>
                <van-cell title="市场合作" is-link @click="goRouter('/marketCooperation')"/>
                <van-cell title="关于我们" is-link @click="goRouter('/aboutUs')"/>
            </van-cell-group>
            <div class="popOther">
                <div class="popOtherItem" @click="headNav('wechat')">
                    <div>
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/wechat.png" size="1.6rem"/>
                    </div>
                    <div class="popOtherItemTitle"> 公众号</div>
                </div>
                <div class="popOtherItem" @click="headNav('smallProgram')">
                    <div>
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/smallProgram.png"  size="1.6rem"/>
                    </div>
                    <div class="popOtherItemTitle">小程序</div>
                </div>
                <div class="popOtherItem" @click="headNav('phone')">
                    <div>
                        <van-icon name="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/phone.png"  size="1.6rem"/>
                    </div>
                    <div class="popOtherItemTitle">APP</div>
                </div>
            </div>
        </van-popup>
        <van-dialog v-model="show1" :title="null" :showConfirmButton="false" width="220" :overlay="true" closeable  close-icon-position="bottom-right" :closeOnClickOverlay="true">
            <div  v-if="type == 'wechat'" class="otherDownLoad">
                <div><van-image width="150" height="150" src="https://web.oss-cn-chengdu.aliyuncs.com/website/qrCodeOfficial.jpg"/></div>
                <div class="otherDownLoadTitle">瑞智公众号</div>
                <div class="otherDownLoadText">长按图片下载保存到手机</div>
            </div>
            <div v-if="type == 'smallProgram'" class="otherDownLoad">
                <div><van-image width="150" height="150"  src="https://web.oss-cn-chengdu.aliyuncs.com/website/smallProgram.jpg"/></div>
                <div class="otherDownLoadTitle">财瑞通小程序</div>
                <div class="otherDownLoadText">长按图片下载保存到手机</div>
            </div>
           <div v-if="type == 'phone'" class="otherDownLoad">
               <div> <van-image width="150" height="150"  src="https://web.oss-cn-chengdu.aliyuncs.com/website/appDownload.png"/></div>
               <div class="otherDownLoadTitle">财瑞通APP </div>
               <div class="otherDownLoadText">扫码后进行下载</div>
           </div>
           
        </van-dialog>
        <van-row>
            <van-col>
                <div class="toTop" @click="clickToTop">
                     <van-image width="40" height="40" src="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/toTop.png"/>
                </div>
            </van-col>
        </van-row>
    </div>
</template>
<script>
 import bus from '@/utils/bus.js' 
export default {
    data(){
        return{
            show:false,
            show1:false,
            type:""
        }
    },
    methods:{
        showPop(){
            this.show = true;
        },
        goRouter(val){
            if(this.$route.path != val){
                 this.$router.push({path:val})
            }else{
                 this.show = false;
            }
        },
        headNav(item){
            this.show = false;
            this.show1 = true;
            this.type = item
        },
        clickToTop(){
              document.body.scrollTop = document.documentElement.scrollTop = 0
        }
    },
    mounted(){
       bus.$on('message', (e) => {
           this.show1 = true;
           this.type = e;
       })
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';
::v-deep .van-cell-group--inset {
     margin:0; 
    border-radius: 0; 
}
.popOther{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-top: 2rem;
    .popOtherItem{
        text-align: center;
        box-shadow: 0 0 5px 0 #ccc;
        padding: 1.2rem 1.3rem;
        width: 3rem;
        border-radius: .8rem;
        .popOtherItemTitle{
                font-size: .81rem;
                padding-top: .2rem;
        }
    }
}
::v-deep .popLog{
    background: none;
}
.toTop{
    position: fixed;
    bottom: 2rem;
    right: .5rem;
    z-index: 99999;
}
.otherDownLoad{
    text-align: center;
    padding: .6rem;
    .otherDownLoadTitle{
        font-size: 0.975rem;
        margin-top: .3rem;
        font-weight: bold;
    }
    .otherDownLoadText{
        font-size: 0.8125rem;
        color: #9999;
        margin-top: .5rem;
    }
           
}
</style>