<template>
    <div class="home">
        <!-- 头部 -->
        <van-row>
            <van-col span="24">
                <headComponent></headComponent>
            </van-col>
        </van-row>
        <div class="bannerBg" :class="'bannerBg'+active"> </div>
        <!-- tab切换 -->
        <van-tabs class="tabsWrapBox" v-model="active" color="#4D96F4">
            <van-tab title="企业文化" class="tabsWrap">
                <div class="comItem item1">
                    <div class="comItemTitle">愿景</div>
                    <div class="content">
                        致力打造金融科技产业生态的重要参与者
                        推动金融科技产业生态圈健康可持续发展
                    </div>
                </div>
                <div class="comItem item2">
                    <div class="comItemTitle">使命</div>
                    <div class="content">
                        为客户创造价值
                        为社会创造财富
                    </div>
                </div>
                <div class="comItem item3">
                    <div class="comItemTitle">价值观</div>
                    <div class="content">
                        <div class="infoItem">
                            信任
                        </div>
                        <div class="infoItem">
                            责任
                        </div>
                        <div class="infoItem">
                            高效
                        </div>
                        <div class="infoItem">
                            创新
                        </div>
                        <div class="infoItem">
                            奉献
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="招贤纳士"  class="tabsWrap tabsWrapB">
                <div class="tabsWrapIntro">
                    成都瑞智数联科技有限公司（简称“瑞智科技”），汇集了众多从事技术研发、数据处理、财富管理、资产管理、产品策划与运营、营销等多方面的优秀专业人才。
                    <br><br>
                    管理团队核心成员主要由来自国内外知名企业的高级管理人员所组成。
                    <br><br>
                    欢迎您加入成都瑞智数联科技有限公司，成为我们的伙伴，请将简历投递至HR@taotra.com。
                </div>
                <van-collapse class="inviteWrap" v-model="activeNames">
                    <van-collapse-item   v-for="(item,index) in itemList" :name="index" :key="index" v-loading="loading">
                        <div slot="title">
                            <div class="positionTitle">{{item.name}}{{item.company ? `(${item.company})` : ''}}</div>
                            <div class="positionWrap">
                                <div class="itemWrap">
                                    <span>工作地点：{{item.base}}</span>
                                    <span>工作经验：{{item.workExperience}}年以上</span>
                                </div>
                                <div class="itemWrap">
                                    <span>学历要求：{{item.education}}</span>
                                    <span>招聘人数：{{item.numbers}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="contentWrap" slot="default">
                            <div style="margin-bottom:1.44rem;">
                                职位描述:<br/>
                                {{item.jobDesc}}
                            </div>
                            <div>
                                任职要求:<br/>
                                {{item.jobRequirement}}
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </van-tab>
            <van-tab title="联系我们"  class="tabsWrap">
                <div class="locationWrap">
                    <div class="addressWrap">
                        <div class="cityWrap">
                            <div>成都</div>
                            <div>四川省成都市双流区天府国际基金小镇投资服务中心3楼</div>
                        </div>
                        <div class="triangle">

                        </div>
                        <div class="logo">
                            <img src="../../assets/image/maodian.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="otherWrap">
                    <div class="otherItem">
                        <img src="../../assets/image/dian.png" alt="">
                        <div class="right">
                            <span>电话</span>
                            <span>028-85028556</span>
                        </div>
                    </div>
                    <div class="otherItem">
                        <img src="../../assets/image/youjian.png" alt="">
                        <div class="right">
                            <span>邮箱</span>
                            <span>marketing@taotra.com</span>
                        </div>
                    </div>
                    <div class="otherItem">
                        <img src="../../assets/image/dingwei.png" alt="">
                        <div class="right">
                            <span>地址</span>
                            <span>四川省成都市双流区天府国际基金小镇投资服务中心3楼</span>
                        </div>
                    </div>
                </div>
                <!-- 联系我们 -->
                <div class="contactUsWrap">
                    <div class="title">
                        <span>联系</span>
                        <span>我们</span>
                    </div>
                    <div class="contactUsForm">
                        <div class="formItem">
                            <van-field  label="您的姓名" v-model="ruleForm.name" placeholder="请输入姓名" />
                        </div>
                        <div class="formItem">
                            <van-field  label="您的电话" v-model="ruleForm.phone" placeholder="请输入电话" />
                        </div>
                        <div class="formItem">
                            <van-field   type="textarea"  v-model="ruleForm.content" label="" placeholder="请输入留言内容" />
                        </div>
                    </div>
                    <div class="formBtn" @click="submit">
                        <van-button  type="info" style="width:100%" loading-text="提交中..." :loading="submitLoading">提交留言</van-button>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <!-- 脚部 -->
        <van-row>
            <van-col span="24">
                <footComponent></footComponent>
            </van-col>
        </van-row>
    </div>
</template>

<script>
import headComponent from '@/views/common/header.vue';
import footComponent from '@/views/common/footer.vue';
import api from '@/api/api.js'
export default {
    name:'home',
    components:{
        headComponent,
        footComponent
    },
    data(){
        return{
            active:2,
            activeNames:[],
            itemList:[],
            ruleForm:{
                name:"",
                phone:"",
                content:""
            },
            submitLoading:false,
            loading:false

        }
    },
    created(){
      this.init()
    },
    methods:{
        init(){
            let params = {
                current:1,
                size:10
            }
            this.loading = true;
            api.recruitment(params).then(res=>{
                 this.loading = false;
               if(res.data.code == 0){
                   this.itemList = res.data.data.records;
               }
            })
        },
        submit(){
            if(this.ruleForm.name.length == 0){
                this.$toast.fail('姓名不能为空');
            }else if(this.ruleForm.phone.length == 0){
                this.$toast.fail('电话不能为空');
            }else if(this.ruleForm.content.length == 0){
                this.$toast.fail('内容不能为空');
            }else{
                let params = {
                    name:this.ruleForm.name,
                    phone:this.ruleForm.phone,
                    content:this.ruleForm.content
                }
                this.submitLoading = true;
                api.contactUs(params).then(res=>{
                    this.submitLoading = false;
                if(res.data.code == 0){
                    this.$toast.success('操作成功');
                    this.ruleForm = {
                            name:"",
                            phone:"",
                            content:""
                        }
                }
                })
            }
            
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.home {
    min-height:100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.bannerBg0{
    background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-3.png') no-repeat;
}
.bannerBg1{
    background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-8.png') no-repeat;
}
.bannerBg2{
    background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-7.png') no-repeat;
}
.bannerBg {
    
    background-size: 100% 100%;
    width: 100%;
    height: 11.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

::v-deep .van-tabs__wrap {
    box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(0, 0, 0, 0.06);
}
.tabsWrapBox{

    .tabsWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.25rem;
        .item1{
            background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-4.png') no-repeat;
            .content {
                width: 14.9rem;
                height: 2.1rem;
                font-size: 0.8rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 1rem;
            }
        }
        .item2{
            background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-5.png') no-repeat;
            .content {
                width: 5.8rem;
                height: 2.1rem;
                font-size: 0.8rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 1rem;
            }
        }
        .item3{
            background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-6.png') no-repeat;
            .content {
                width: 100%;
                display:flex;
                align-items: center;
                font-size: 0.8rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                justify-content:space-between;
                padding: 0 0.6rem;
                box-sizing: border-box;
                .infoItem {
                    width: 3.125rem;
                    height: 3.125rem;
                    line-height: 3.125rem;
                    color: #FFFFFF;
                    background: rgba(77,150,244,.2);
                    border-radius: 50%;
                    position: relative;
                }
                .infoItem::after {
                    content: " ";
                    width: 3.7rem;
                    height: 3.7rem;
                    display: block;
                    position: absolute;
                    top: -0.3rem;left: -0.3rem;
                    background: #4D96F4;
                    opacity: 0.2;
                    border-radius: 50%;
                }
            }
        }
        .comItem {
            background-size: 100% 100%;
            // max-width: 21.562rem;
            // min-width: 19.5rem;
            width: 95%;
            height: 13.125rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 0.9rem;

            .comItemTitle {
                height: 0.9rem;
                font-size: 1rem;
                font-family: PingFang SC;
                font-weight: 800;
                color: #FFFFFF;
                line-height: 0.9rem;
                margin-bottom:1.031rem;
            }

        }

        .locationWrap {
            margin-top:- 1.25rem;
            position: relative;
            background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/ditu.png') no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 18.8rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 0.9rem;

            .addressWrap {
                width: 17.4rem;
                height: 5.9rem;
                // background:red;
                display:flex;
                flex-direction: column;
                justify-content:space-between;
                align-items:center;
                position: relative;
                top: -2.2rem;
                left: 1.5rem;

                .cityWrap {
                    width: 100%;
                    min-height: 4rem;
                    background: #437CD3;
                    border-radius: 0.4rem;
                    padding: 0.9rem .4rem;
                    box-sizing: border-box;
                    position: relative;
                    
                    div:nth-child(1) {
                        height: 0.9rem;
                        font-size: 0.9rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: .9rem;
                        margin-bottom:0.6rem;
                        text-align: left;
                        text-indent: 0.2rem;
                    }
                    div:nth-child(2) {
                        width:100%;
                        height: 0.7rem;
                        font-size: 0.65rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 0.7rem;
                        text-align: left;
                    }
                }

                .triangle {
                    width:0;
                    height:0;
                    border-left:.35rem solid transparent;
                    border-right: .35rem solid transparent;
                    border-top:.35rem solid #437CD3;
                    margin-bottom:0.3rem;

                }

                img{
                    width: 1.3rem;
                    height: 1.3rem
                }
            }

        }
            .otherWrap {
                width: 100%;
                display: flex;
                flex-direction:column;
                align-items: center;
                img{
                    width: 2.4rem;
                    margin-right: 0.84rem;
                }
                .otherItem{
                    width: 85%;
                    height: 4.4rem;
                    background: #FFFFFF;
                    border: 1px solid #CCCCCC;
                    border-radius: 0.4rem;
                    margin-bottom:0.5rem;
                    display:flex;
                    align-items: center;
                    padding: 1rem 0.7rem;
                    box-sizing: border-box;
                    font-size: 0.8rem;
                    
                    font-size: 0.7rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 0.8rem;

                    .right{
                        display:flex;
                        flex-direction: column;

                        span:first-child{
                            margin-bottom: 0.45rem;
                        }
                    }
                }
            }

            .contactUsWrap{
                width: 100%;
                height: 22.2rem;
                margin-top: 0.6rem;
                background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/bg3.png') no-repeat;
                background-size: 100% 100%;
                
                .title{
                    width: 100%;
                    height: 4.5rem;
                    line-height: 4.5rem;
                    text-align: center;
                    font-size: 1rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;

                    span:last-child{
                        color: #4D96F4;
                    }
                }

                .contactUsForm{
                    // width: 100%;
                    // display: flex;
                    // box-sizing: border-box;
                    // flex-direction: column;
                    // align-items: center;

                    .formItem{
                        padding: 0 0.9rem;
                        margin-bottom: 0.5rem;
                    }

                }
                .formBtn {
                    width: calc(100% - 1.9rem);
                    height: 2.5rem;
                    line-height: 2.5rem;
                    text-align: center;
                    // background: #437CD3;
                    margin:0 auto;
                    font-size: 0.9rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-top: 1.6rem;
                }
            }
    }
}


.tabsWrapB {

    .tabsWrapIntro{
        width: 100%;
        height: 17.97rem;
        background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/bg4.png') no-repeat;
        background-size: 100% 100%;
        margin-top: -1.25rem;
        padding: 2.2rem 1.4rem 0rem 1rem;
        box-sizing: border-box;

        font-size: 0.8rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 1.1rem;
    }

    .inviteWrap{
        width: 100%;
        
        .positionWrap {
            display:flex;
        }
        .positionTitle{
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #437CD3;
            line-height: 0.8rem;
            margin-bottom:0.9rem;
            margin-top:0.58rem;
        }
        .itemWrap{
            width: 100%;
            display:flex;
            flex-direction: column;
            span{
                position: relative;
                font-size: 0.8rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                line-height: 0.8rem;
                margin-bottom:0.8rem;
            }
        }
        .itemWrap:last-child>span {
            margin-left: 1rem;
        }
        .itemWrap:first-child>span::after {
            content: " ";
            position: absolute;
            right: 0rem;top: 0.08rem;
            width:1px;
            height:0.5rem;
            background-color:#E5E5E5;
        }
        
    }

    .contentWrap{
        text-align: justify;

        font-size: 0.8rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 1.1rem;
    }
}
</style>