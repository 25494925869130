<!--
 * @Author: YourName
 * @Date: 2022-09-28 11:21:53
 * @LastEditTime: 2023-09-14 11:33:19
 * @LastEditors: YourName
 * @Description: 
 * @FilePath: \website_taotrade_mobile\src\views\productService\common\serviceContent.vue
 * 版权声明
-->
<template>
    <div class="serviceContent">
        <van-row>
            <van-col span="22" class="serviceContentItem">
                <div>
                    <div>
                        <van-image width="100%" height="auto" fit="contain" src="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/technologyPlatform.png"/>
                    </div>
                    <div class="serviceContentItemTitle">科技服务平台</div>
                    <div class="serviceContentItemDescption">一站式科技服务，提供专业的客户管理、渠道管理、产品管理、营销管理、清算管理、数据分析等服务，为展业各关键环节提供系统支持；提供专属定制化服务</div>
                </div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="22" class="serviceContentItem">
                <div>
                    <div>
                        <van-image width="100%" height="auto" fit="contain" src="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/productChain.png"/>
                    </div>
                    <div class="serviceContentItemTitle">产品服务供应链</div>
                    <div class="serviceContentItemDescption">丰富的产品服务，采用智慧投研系统引入多样化的证券基金类产品、安全保障类产品、专属定制化组合等专业服务；提供相关法律、法规、政策咨询</div>
                </div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="22" class="serviceContentItem">
                <div>
                    <div>
                        <van-image width="100%" height="auto" fit="contain" src="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/assetAllocation.png"/>
                    </div>
                    <div class="serviceContentItemTitle">资产配置定制化</div>
                    <div class="serviceContentItemDescption">利用自研的资产定价分析系统、基金评价体系、投资组合管理等基于机器学习算法的投资辅助系统，辅助金融机构和投资者识别投资机会，管理投资风险；通过科学的投资策略、严谨的业务模式和成熟的风控体系，针对多元化的场景，量身定制专属资产配置方案</div>
                </div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="22" class="serviceContentItem">
                <div>
                    <div>
                        <van-image width="100%" height="auto" fit="contain" src="https://web.oss-cn-chengdu.aliyuncs.com/wap-website/integratedMarketing.png"/>
                    </div>
                    <div class="serviceContentItemTitle">整合营销</div>
                    <div class="serviceContentItemDescption">高效整合机构流量和三方平台流量，基于大数据技术进行全方位的用户分析，提供更精准的服务和管理工具，多种触达方式，提升经营效率，降低获客成本</div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>
<script>
export default {
    name:'serviceContent',
    data(){
        return{

        }
    }
}
</script>