<template>
    <div class="home">
        <!-- 头部 -->
        <van-row>
            <van-col span="24">
                <headComponent></headComponent>
            </van-col>
        </van-row>
        <!-- 列表区域 -->
            <!-- 广告图片 -->
            <div class="cooperation-banner" v-if="activeNamecol<0">
                <div class="cooperation-bannerText">
                    <div class="cooperation-bannerTextTitle"></div>
                </div>
            </div>
            <!-- 资讯动态列表 -->
            <div class="information-list" v-if="activeNamecol<0">
                <div class="information-list-item" v-for="(item,index) of informationList" :key="index" @click="showDetail(index)">
                 <van-row>
                     <van-col span="8">
                        <div class="itemLeft">
                            <van-image width="auto" :src="item.cover[0].url"/>
                        </div>
                     </van-col>
                     <van-col span="15" :offset="1">
                        <div class="itemRight">
                            <div class="title">{{item.title}}</div>
                            <div class="footer">
                                <div>{{getYearMonth(item.createTime)}}</div>
                                <div>来源：{{item.source == null ||item.source == '' ? '平台' : item.source}}</div>
                            </div>
                        </div>
                     </van-col>
                 </van-row>
                  
                   
                </div> 
            </div>
             <van-loading type="circular" class="loading-bg" color="#1989fa" :vertical="false" v-show="loading"/>
            <!-- 分页切换栏 -->
            <div class="paging-wrap" v-if="activeNamecol<0">
                <van-pagination v-model="search.current" :page-count="total" mode="simple" @change="changePagination" />
            </div>
            <!-- 新闻区域 -->
            <div class="newsDetail" v-if="activeNamecol>=0">
                <div class="topNavs">
                    <span @click="goBack"> <img src="../../assets/image/zhuye.png" alt=""></span>
                    <span><span @click="goBack">资讯动态 > </span> {{ fontNumber(newsDetailItem.title)}}</span>
                </div>
                <div class="newsTitleWrap">
                    <div class="title">
                        {{newsDetailItem.title}}
                    </div>
                   
                    <div class="otherInfo">
                        <span style="margin-right: 1.1rem">{{ getYearMonth(newsDetailItem.createTime)}}</span>  
                        <span>{{newsDetailItem.source}}</span>
                    </div>
                </div>
                <!-- 新闻内容区域 -->
                <div class="newsContent" v-html="newsDetailItem.content">
                </div>
                <!-- 切换页码 -->
                <div class="newsDetailFooter" style="padding: 5%">
                    <div class="lastCol"  @click="toLast" >  {{ lastNews.title ? '上一页' : '返回列表'}}</div>
                    <div class="nextCol"  @click="toNext" >  {{ nextNews.title ? '下一页' : '返回列表'}}</div>
                </div>
            </div>
        <!-- 脚部 -->
        <van-row>
            <van-col span="24">
                <footComponent></footComponent>
            </van-col>
        </van-row>
      
    </div>
</template>

<script>
import headComponent from '@/views/common/header.vue'
import footComponent from '@/views/common/footer.vue'
import api from '@/api/api.js'
export default {
    name:'home',
    components:{
        headComponent,
        footComponent
    },
    data(){
        return{
            informationList:[],
            activeNamecol:0,
            newsDetailItem:{},
            listTable: [],
            lastIndex: -1,
            lastNews: {},
            nextIndex: -1,
            nextNews: {},
            total:0,
            search:{
                current:1,
                size:5
            },
            loading:false,
            content:""
            

        }
    },
    methods:{
        getYearMonth(date){
            date = date.trim().split(" ");
            date = date[0]
            return date
        },
        fontNumber (date) {
            let length = date.length
            if (length > 20) {
                var str = ''
                str = date.substring(0, 20) + '......'
                return str
            } else {
                return date
            }
        },
        gotoDetail(item,index) {
            this.content = item;
            this.activeNamecol = index +1;
            console.log(item)
        },
        // 获取数据源
        getinit(){
            let params = {
                current:this.search.current,
                size:this.search.size
            }
            this.loading = true;
            api.newsList(params).then(res=>{
                 this.loading = false;
                if(res.data.code == 0){
                    this.informationList = res.data.data.records;
                    this.total =  res.data.data.pages;
                }
            })
        },
        changePagination(val){
            this.search.current = val;
            this.getinit()
        },
        showDetail(index) {
            console.log(index,'index')
            this.activeNamecol = index
            this.newsDetailItem = this.informationList[index]
            if (this.informationList[index - 1]) {
                this.lastIndex = index - 1
                this.lastNews = this.informationList[index - 1]
            } else {
                this.lastIndex = -1
                this.lastNews = {}
            }
            if (this.informationList[index + 1]) {
                this.nextIndex = index + 1
                this.nextNews = this.informationList[index + 1]
            } else {
                this.nextIndex = -1
                this.nextNews = {}
            }
            document.documentElement.scrollTop = 0
        },
        toLast() {
            this.showDetail(this.lastIndex);
        },
        toNext() {
            this.showDetail(this.nextIndex);
        },
        goBack(){
            this.activeNamecol = -1
            document.documentElement.scrollTop = 0
        }
    },
    created(){
        this.getinit()
        this.activeNamecol = -1
    }
}
</script>
<style>
img{
    width: 100%;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.home {
    background-color:#FBFBFB;
    height:100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.cooperation-banner {
  background: url('https://web.oss-cn-chengdu.aliyuncs.com/wap-website/banner-1.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 11.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.information-list {
    margin-top:0.6rem;
    box-sizing: border-box;
    .information-list-item {
        width: 100%;
        min-height:6.25rem;
        background: #FFFFFF;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        align-items:flex-start;
        padding: 0.87rem 0.93rem 0.81rem 0.93rem;
        box-sizing: border-box;

        .itemLeft {
            margin-right:1.12rem
        }

        .itemRight {
          
            .title {
                max-width: 13.1rem;
                height: 2.3rem;
                font-size: 0.9rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                line-height: 1.2rem;
                margin-bottom:1.8rem;
                // 多行溢出隐藏省略号
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .footer {
                display: flex;
                justify-content: space-between;
                font-size: 0.7rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
            }
        }


    }
    .information-list-item:last-child {
        border: none;
    }
}

.paging-wrap {
//    width: 10.9rem;
   margin:0 auto;
   margin-top:1.1rem;
   margin-bottom: 1.4rem;
//    display:flex;
//    justify-content:space-between;

   .com-buttton {
        width: 4.8rem;
        height: 1.7rem;
        background: #FFFFFF;
        border: 1px solid #4D96F4;
        border-radius: 0.9rem;
        text-align: center;
        line-height: 1.7rem;
        font-size: 0.7rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4D96F4;
   }
}

.newsDetail {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .topNavs {
        width: 100%;
        height: 2.2rem;
        background-color:#4D96F4;
        padding:0rem 1rem;
        display:flex;
        box-sizing: border-box;
        align-items: center;

        font-size: 0.8rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;

        img{
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.3rem;
        }

    }

    .newsTitleWrap{
        padding: 1.84rem 0.93rem;
        box-sizing: border-box;
        .title {
            width: 100%;
            min-height: 1.3rem;
            font-size: 1.3rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            line-height: 1.3rem;
            margin-bottom:1.2rem;
        }

        .otherInfo {
            font-size: 0.8rem;
            font-family: Arial;
            font-weight: 400;
            color: #999999;
            padding-bottom: 1.3rem;
        }
    }
    
    .newsContent{
        overflow: hidden;
        width: calc(100% - 1.9rem);
        margin: 0 auto;
        box-sizing: border-box;
        min-height:28.3rem;
        padding: 1.2rem 0rem;
        border-bottom: 1px solid #EEEEEE;
        border-top: 1px solid #EEEEEE;
    }

    .newsDetailFooter {
        width: calc(100% - 1.9rem);
        height: 2.5rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .lastCol,.nextCol{
            width: 4.8rem;
            height: 1.7rem;
            background: #FFFFFF;
            border: 0.04rem solid #4D96F4;
            border-radius: 0.9rem;
            font-size: 0.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #4D96F4;
        }
    }
}
.loading-bg{
    text-align: center;
}
</style>